import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataForSingleEventCaseName } from "../features/SingleEventCaseSlice";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import MapContainer from "../components/map/MapContainer";
import { getScaleData, getSlideImageData } from "../features/MapSlice";
import { setCaseName, setEventName } from "../features/TabNameSlice";
import CommonAccordion from "./CommonAccordian";
import Markdown from 'react-markdown'
const SlideViewer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data, loading } = useSelector((state) => state.singleCase);
  const { caseName } = useSelector((state) => state.tab);
  // const { imageData, scaleData, loading: mapLoading } = useSelector((state) => state.map);

  useEffect(() => {
    let pathArray = location.pathname.split("/");
    if (pathArray.length === 3) {
      dispatch(getDataForSingleEventCaseName({ eventName: pathArray[1], caseName: pathArray[2] }));
      dispatch(getSlideImageData({ eventName: pathArray[1], caseName: pathArray[2] }));
      dispatch(getScaleData({ eventName: pathArray[1], caseName: pathArray[2] }));
      dispatch(setEventName(pathArray[1]));
      dispatch(setCaseName(pathArray[2]));
    }
  }, [location.pathname, dispatch]);
  const caseInfoArray = data?.type === 'md'
                        ? data?.info
                        : data?.info?.split("\n");
  // const caseInfoArray = data?.info?.split("\n");

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="header">
            <div className="heading">
              <strong>Slide Name:</strong>
              <span> {caseName}</span>
            </div>
            <a
              href="https://www.medprimetech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={process.env.PUBLIC_URL + "/image.png"}
                alt="cilika"
                width='auto'
                height={20}
              />
            </a>
          </div>

          <CommonAccordion
            heading="Case Info:"
            content={caseInfoArray}
          />
          <br />
          <MapContainer />
        </>
      )}
    </div>
  );
};

export default SlideViewer;
