import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { environment } from "../environment";
import Markdown from 'react-markdown'

const CommonTable = ({ rows }) => {
  const location = useLocation();
  let path = location.pathname.split('/')[1]

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">No</TableCell>
            <TableCell align="left">SlideName</TableCell>
            <TableCell align="left">Case Info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows?.map((row, i) => (
              <TableRow
                key={row?.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                >
                  {i + 1}
                </TableCell>
                <TableCell align="left">
                  <a
                    href={`/${path}/${row?.name}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row?.name}
                  </a>
                </TableCell>
                <TableCell align="left"><Markdown >{row?.info?.split("\n")[0]}</Markdown></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonTable;
